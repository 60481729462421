<template>
  <div id="packages" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >PAQUETES DE PRESTACIONES</v-row
      ><v-row no-gutters justify="end" class="mr-12 mb-4">
        <v-col
          cols="12"
          lg="6"
          md="5"
          sm="4"
          xs="12"
          class="selectEmpresa"
          align-self="end"
        >
          <v-row class="ml-12">
            <label for="enterprise" class="ml-5 mr-2">Empresa Pagadora</label>
            <select
              name="enterprise"
              id="enterprise"
              v-model="enterprise"
              @change="listar()"
            >
              <option selected disabled value="0">Selecciona Empresa</option>
              <option
                v-for="enterprise in enterprises"
                :key="enterprise.id"
                v-bind:value="enterprise.id"
              >
                {{ enterprise.razonSocial }}
              </option>
            </select>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="10" xs="12">
          <v-card-title>
            <router-link to="/addPackage" id="nuevoPaquete">
              <button class="breakSearch botonAmarillo">Alta de Paquete</button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="packages"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Paquetes por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.Id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">{{ item.nombrePaquete }}</td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="editItem(item)"
                      >fa-edit</v-icon
                    >
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="viewItem(item)"
                      >fa-eye</v-icon
                    >
                    <v-icon color="red" small @click="temporal(item)"
                      >fa-trash-alt</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="advice = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteItem()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Paquete de Prestación</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG DE DATOS-->
    <v-dialog v-model="dialog" scrollable max-width="370px">
      <v-card>
        <v-card-title class="orange white--text"
          >Información de Paquete</v-card-title
        >
        <v-card-text id="info">
          <div class="form-group mt-5">
            <label>Id Paquete:</label>
            {{ packageselect.id }}
          </div>
          <div class="form-group">
            <label>Nombre del Paquete:</label>
            {{ packageselect.nombrePaquete }}
          </div>
          <div class="form-group" v-if="packageselect.prestaciones.length != 0">
            <label>Prestaciones:</label>
            <li
              v-for="presta in packageselect.prestaciones"
              :key="presta.prestacion.id"
            >
              {{ presta.prestacion.nombrePrestacion }}
            </li>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      headers: [
        {
          text: "N°",
          align: "center",
          value: "id",
        },
        {
          text: "Nombre de paquete de Prestaciones",
          align: "center",
          value: "nombrePaquete",
        },
        {
          text: "Editar  /   Ver  / Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      packages: [],
      packageselect: {
        id: 0,
        nombrePaquete: "X",
        prestaciones: [],
      },
      respuesta: "",
      advice: false,
      id: "",
      confirmation: false,
      edit: false,
      vacio: false,
      enterprises: [],
      enterprise: localStorage.empresaIdGlobal,
    };
  },
  methods: {
    editItem(item) {
      localStorage.packageId = item.id;
      this.$router.push("/editPackage");
    },
    temporal(item) {
      this.id = item.id;
      this.respuesta =
        "¿ Seguro que desea eliminar el Paquete de prestaciones " +
        item.nombrePaquete +
        " ?";
      this.advice = true;
    },
    deleteItem() {
      this.advice = false;
      this.respuesta = "";
      this.show = true;
      axios
        .delete(Server + "/paquetesPrestaciones/" + this.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.id = "";
          this.respuesta =
            "El paquete de prestaciones fue eliminado con éxito.";
          this.show = false;
          this.confirmation = true;
          this.listar();
        })
        .catch((e) => {
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.respuesta = "El paquete de prestaciones no fue eliminado.";
            this.confirmation = true;
          }
        });
    },
    viewItem(item) {
      this.packageselect = item;
      this.dialog = true;
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/paquetesPrestaciones/por-empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.packages = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerEmpresas() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.obtenerEmpresas();
    this.listar();
  },
};
</script>